/* RadioGroup styles */
.radioGroup {
  @apply bg-white/[0.04] rounded-md p-[3px] flex;
}

.radioButton {
  @apply relative flex-grow;
}
.input {
  @apply opacity-0 absolute top-0 left-0 right-0 bottom-0;

  &:not(:disabled) {
    @apply cursor-pointer;
  }

  &:disabled:not(:checked) ~ .label {
    @apply text-white/[0.3];
  }

  &:checked ~ .label {
    @apply bg-white/[0.08] rounded-[3px];
  }
}
.label {
  @apply py-1 px-3 duration-200 block text-center text-xs;
}
